import React from "react";
import { Accordion, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

class SubMenuLogs extends React.Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    const { icon, title } = this.props;

    return (
      <Nav.Item className={classNames({ open: !this.state.collapsed })}>
        <Accordion>
          <Accordion.Toggle
            as={Nav.Link}
            variant="link"
            eventKey="0"
            onClick={this.toggleNavbar}
          >
            <FontAwesomeIcon icon={icon} className="mr-2" />
            {title}
            <FontAwesomeIcon
              icon={this.state.collapsed ? faCaretDown : faCaretUp}
              className="float-right"
            />
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="0">
            <Nav className="flex-column">
              <Nav.Item className="nav-link">
                <img
                  src="/images/logoOc.svg"
                  alt="Logo"
                  className="img-fluid"
                />
              </Nav.Item>

              <Nav.Item className="nav-link">
                <NavLink
                  exact={true}
                  activeClassName="active"
                  to="/logs/apiolecams"
                  role="button"
                >
                  API Olecams
                </NavLink>
              </Nav.Item>
              <hr></hr>
              <Nav.Item className="nav-link">
                <Nav.Item className="nav-link">
                  <img src="/images/MOW.svg" alt="Logo" className="img-fluid" />
                </Nav.Item>
              </Nav.Item>
              <Nav.Item className="nav-link">
                <NavLink
                  exact={true}
                  activeClassName="active"
                  to="/logs/rooms"
                  role="button"
                >
                  Rooms
                </NavLink>
              </Nav.Item>

              <Nav.Item className="nav-link">
                <NavLink
                  exact={true}
                  activeClassName="active"
                  to="/logs/apimow"
                  role="button"
                >
                  API MOW
                </NavLink>
              </Nav.Item>

              <hr></hr>
              <Nav.Item className="nav-link">
                <Nav.Item className="nav-link">
                  <img src="/images/vce.png" alt="Logo" className="img-fluid" />
                </Nav.Item>
                <NavLink
                  exact={true}
                  activeClassName="active"
                  to="/logs/videochatmb"
                  role="button"
                >
                  vce & videochatmb
                </NavLink>
              </Nav.Item>

              <hr></hr>
              <Nav.Item className="nav-link">
                <Nav.Item className="nav-link">
                  <img
                    src="/images/logoOT.svg"
                    alt="Logo"
                    className="img-fluid"
                  />
                </Nav.Item>
                <NavLink
                  exact={true}
                  activeClassName="active"
                  to="/logs/oletraffic"
                  role="button"
                >
                  Oletraffic
                </NavLink>
              </Nav.Item>

              <Nav.Item className="nav-link">
                <NavLink
                  exact={true}
                  activeClassName="active"
                  to="/logs/voluum"
                  role="button"
                >
                  Voluum
                </NavLink>
              </Nav.Item>

              <Nav.Item className="nav-link">
                <NavLink
                  exact={true}
                  activeClassName="active"
                  to="/logs/traffic"
                  role="button"
                >
                  Traffic
                </NavLink>
              </Nav.Item>
            </Nav>
          </Accordion.Collapse>
        </Accordion>
      </Nav.Item>
    );

    // return (
    //   <div>
    //     <NavItem
    //       onClick={this.toggleNavbar}
    //       className={classNames({ "menu-open": !this.state.collapsed })}
    //     >
    //       <NavLink className="dropdown-toggle" href="#">
    //         <FontAwesomeIcon icon={icon} className="mr-2" />
    //         {title}
    //       </NavLink>
    //     </NavItem>
    //     <Collapse
    //       isOpen={!this.state.collapsed}
    //       navbar
    //       className={classNames("items-menu", {
    //         "mb-1": !this.state.collapsed
    //       })}
    //     >
    //       {items.map(item => (
    //         <NavItem key={item} className="pl-4">
    //           <NavLink>{item}</NavLink>
    //         </NavItem>
    //       ))}
    //     </Collapse>
    //   </div>
    // );
  }
}

export default SubMenuLogs;
